import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { GraphicModel } from '../charts-model';

interface Props {
    graphic: GraphicModel;
}

ChartJS.register( CategoryScale, LinearScale, PointElement, BarElement, Title, Tooltip, Legend );
ChartJS.defaults.color = 'white';
  
const OneLineChart: React.FC<Props> = ({ graphic }) => {

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top' as const
            },
            title: {
                display: true,
                text: graphic.titre,
                font: { 
                    weight: 'bold', 
                    size: 20 
                }
            }
        },
    };

    const data = {
        labels: graphic.labelsX,
        datasets: [
            {
                label: graphic.chartDatas[0].labelY,
                data: graphic.chartDatas[0].values,
                borderColor: '#36A2EB',
                backgroundColor: '#36A2EB'
            }
        ],
    };

    return (
        <Bar options={options} data={data} />
    );
}

export default OneLineChart;