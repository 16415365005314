export const INITIAL_MIN_MAX_CURRENT: MinMaxCurrentModel = { min: 0, 
                                                             max: 0, 
                                                             current: 0 };

export interface MinMaxCurrentModel {
    min: number;
    max: number;
    current: number;
};

export const INITIAL_NOW: NowModel = { temperatures: INITIAL_MIN_MAX_CURRENT, 
                                       humidites: INITIAL_MIN_MAX_CURRENT,
                                       pressions: INITIAL_MIN_MAX_CURRENT,
                                       vitessesVent: INITIAL_MIN_MAX_CURRENT,
                                       directionVentCourant: '',
                                       totalPrecipitationsAujourdui: 0,
                                       totalPrecipitationsHier: 0 };

export interface NowModel {
    temperatures: MinMaxCurrentModel;    
    humidites: MinMaxCurrentModel;
    pressions: MinMaxCurrentModel;
    vitessesVent: MinMaxCurrentModel;
    directionVentCourant: string;
    totalPrecipitationsAujourdui: number;
    totalPrecipitationsHier: number;
};