import styled from 'styled-components';

export const DivChartStyle = styled.div`
    background: #1D5D75;
    border-radius: 5px;
    margin-bottom: 20px;
    margin-left: 5px;
    margin-right: 5px;
    min-height: 500px;
`;


