import React from 'react';
import { KeyNumberModel } from './statistics-model';
import { DataStatDivStyle, LabelStatSpanStyle, ValueStatSpanStyle } from './styles';

interface Props {
    type: string;
    liste: KeyNumberModel[];
}

const ObjStats: React.FC<Props> = ({liste, type}) => {

    const listItems = liste.map((item) =>
        <DataStatDivStyle key={item.key}>
            <LabelStatSpanStyle>{item.key} : </LabelStatSpanStyle>
            <ValueStatSpanStyle>{item.value} {type}</ValueStatSpanStyle>
        </DataStatDivStyle>
    );

    return (
        <>
        {listItems}
        </>
    );
};
  
export default ObjStats;