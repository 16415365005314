import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { faker } from '@faker-js/faker';
import Cell from '../../Common/cell';
import { ContentStyle } from '../../Common/global-styles';
import { StatisticModel, INITIAL_STATISTIC } from './statistics-model';
import Moment from 'moment';
import ObjStats from './objStats';
import Loader from '../../Common/Loader';
require('moment/locale/fr.js');

const StatisticsView: React.FC = () => {

    const [statistics, setStatistics] = useState<StatisticModel>(INITIAL_STATISTIC);
    const [loading, setLoading] = useState(true);

    const FormatDay = (myDate: Date) => { return Moment(myDate).locale('fr').format('DD/MM/YYYY'); }

    const StatisticsData = () => {
        setLoading(true);
        axios.get('http://chevroliere-meteo.hd.free.fr/statistics.php')
            .then((res) => {
                setStatistics(res.data);
                setLoading(false);
            })
            .catch((e) => { 
                console.log("Statistiques component : " + e.message);

                let stats = {
                    recordsChaleur: [
                        {
                            key: FormatDay(faker.date.between('2021-01-01T00:00:00.000Z', '2023-01-11T00:00:00.000Z')), 
                            value: faker.datatype.float({ min: 38, max: 40 })
                        }, 
                        {
                            key: FormatDay(faker.date.between('2021-01-01T00:00:00.000Z', '2023-01-11T00:00:00.000Z')), 
                            value: faker.datatype.float({ min: 36, max: 38 })
                        }, 
                        {
                            key: FormatDay(faker.date.between('2021-01-01T00:00:00.000Z', '2023-01-11T00:00:00.000Z')), 
                            value: faker.datatype.float({ min: 34, max: 36 })
                        }, 
                        {
                            key: FormatDay(faker.date.between('2021-01-01T00:00:00.000Z', '2023-01-11T00:00:00.000Z')), 
                            value: faker.datatype.float({ min: 32, max: 34 })
                        }, 
                        {
                            key: FormatDay(faker.date.between('2021-01-01T00:00:00.000Z', '2023-01-11T00:00:00.000Z')), 
                            value: faker.datatype.float({ min: 30, max: 32 })
                        }
                    ],
                    recordsFroideur: [
                        {
                            key: FormatDay(faker.date.between('2021-01-01T00:00:00.000Z', '2023-01-11T00:00:00.000Z')), 
                            value: faker.datatype.float({ min: -5, max: -4 })
                        }, 
                        {
                            key: FormatDay(faker.date.between('2021-01-01T00:00:00.000Z', '2023-01-11T00:00:00.000Z')), 
                            value: faker.datatype.float({ min: -4, max: -3 })
                        }, 
                        {
                            key: FormatDay(faker.date.between('2021-01-01T00:00:00.000Z', '2023-01-11T00:00:00.000Z')), 
                            value: faker.datatype.float({ min: -3, max: -2 })
                        }, 
                        {
                            key: FormatDay(faker.date.between('2021-01-01T00:00:00.000Z', '2023-01-11T00:00:00.000Z')), 
                            value: faker.datatype.float({ min: -2, max: -1 })
                        }, 
                        {
                            key: FormatDay(faker.date.between('2021-01-01T00:00:00.000Z', '2023-01-11T00:00:00.000Z')), 
                            value: faker.datatype.float({ min: -1, max: 0 })
                        }
                    ],
                    recordsPluie: [
                        {
                            key: FormatDay(faker.date.between('2021-01-01T00:00:00.000Z', '2023-01-11T00:00:00.000Z')), 
                            value: faker.datatype.float({ min: 35, max: 40 })
                        },
                        {
                            key: FormatDay(faker.date.between('2021-01-01T00:00:00.000Z', '2023-01-11T00:00:00.000Z')), 
                            value: faker.datatype.float({ min: 33, max: 35 })
                        },
                        {
                            key: FormatDay(faker.date.between('2021-01-01T00:00:00.000Z', '2023-01-11T00:00:00.000Z')), 
                            value: faker.datatype.float({ min: 31, max: 33 })
                        },
                        {
                            key: FormatDay(faker.date.between('2021-01-01T00:00:00.000Z', '2023-01-11T00:00:00.000Z')), 
                            value: faker.datatype.float({ min: 29, max: 31 })
                        },
                        {
                            key: FormatDay(faker.date.between('2021-01-01T00:00:00.000Z', '2023-01-11T00:00:00.000Z')), 
                            value: faker.datatype.float({ min: 27, max: 29 })
                        }
                    ],
                    recordsVent : [
                        {
                            key: FormatDay(faker.date.between('2021-01-01T00:00:00.000Z', '2023-01-11T00:00:00.000Z')), 
                            value: faker.datatype.float({ min: 80, max: 100 })
                        },
                        {
                            key: FormatDay(faker.date.between('2021-01-01T00:00:00.000Z', '2023-01-11T00:00:00.000Z')), 
                            value: faker.datatype.float({ min: 75, max: 80 })
                        },
                        {
                            key: FormatDay(faker.date.between('2021-01-01T00:00:00.000Z', '2023-01-11T00:00:00.000Z')), 
                            value: faker.datatype.float({ min: 70, max: 75 })
                        },
                        {
                            key: FormatDay(faker.date.between('2021-01-01T00:00:00.000Z', '2023-01-11T00:00:00.000Z')), 
                            value: faker.datatype.float({ min: 65, max: 70 })
                        },
                        {
                            key: FormatDay(faker.date.between('2021-01-01T00:00:00.000Z', '2023-01-11T00:00:00.000Z')), 
                            value: faker.datatype.float({ min: 60, max: 65 })
                        }
                    ],
                    recordsHumidite : [
                        {
                            key: FormatDay(faker.date.between('2021-01-01T00:00:00.000Z', '2023-01-11T00:00:00.000Z')), 
                            value: faker.datatype.float({ min: 80, max: 100 })
                        },
                        {
                            key: FormatDay(faker.date.between('2021-01-01T00:00:00.000Z', '2023-01-11T00:00:00.000Z')), 
                            value: faker.datatype.float({ min: 75, max: 80 })
                        },
                        {
                            key: FormatDay(faker.date.between('2021-01-01T00:00:00.000Z', '2023-01-11T00:00:00.000Z')), 
                            value: faker.datatype.float({ min: 70, max: 75 })
                        },
                        {
                            key: FormatDay(faker.date.between('2021-01-01T00:00:00.000Z', '2023-01-11T00:00:00.000Z')), 
                            value: faker.datatype.float({ min: 65, max: 70 })
                        },
                        {
                            key: FormatDay(faker.date.between('2021-01-01T00:00:00.000Z', '2023-01-11T00:00:00.000Z')), 
                            value: faker.datatype.float({ min: 60, max: 65 })
                        }
                    ],
                    recordsSecheresse : [
                        {
                            key: FormatDay(faker.date.between('2021-01-01T00:00:00.000Z', '2023-01-11T00:00:00.000Z')), 
                            value: faker.datatype.float({ min: 0, max: 20 })
                        },
                        {
                            key: FormatDay(faker.date.between('2021-01-01T00:00:00.000Z', '2023-01-11T00:00:00.000Z')), 
                            value: faker.datatype.float({ min: 20, max: 25 })
                        },
                        {
                            key: FormatDay(faker.date.between('2021-01-01T00:00:00.000Z', '2023-01-11T00:00:00.000Z')), 
                            value: faker.datatype.float({ min: 25, max: 30 })
                        },
                        {
                            key: FormatDay(faker.date.between('2021-01-01T00:00:00.000Z', '2023-01-11T00:00:00.000Z')), 
                            value: faker.datatype.float({ min: 30, max: 35 })
                        },
                        {
                            key: FormatDay(faker.date.between('2021-01-01T00:00:00.000Z', '2023-01-11T00:00:00.000Z')), 
                            value: faker.datatype.float({ min: 35, max: 40 })
                        }
                    ]
                };

                setStatistics(stats);
                setLoading(false);
            });
    }

    useEffect(() => {
        StatisticsData();
        // eslint-disable-next-line
    }, []);
        
    return (
        <>
            {loading && <Loader />}

            {!loading &&
                <ContentStyle>
                    <Cell title="Records de chaleur" content={<ObjStats type='°C' liste={statistics.recordsChaleur} />} />
                    <Cell title="Records de froideur" content={<ObjStats type='°C' liste={statistics.recordsFroideur} />} />
                    <Cell title="Records de pluie" content={<ObjStats type='mm' liste={statistics.recordsPluie} />} />
                    <Cell title="Records de sécheresse" content={<ObjStats type='%'liste={statistics.recordsSecheresse} />} />
                    <Cell title="Records d'humidité" content={<ObjStats type='%'liste={statistics.recordsHumidite} />} />
                    <Cell title="Records de vent" content={<ObjStats type='Km/h' liste={statistics.recordsVent} />} />
                </ContentStyle>
            }
        </>
    );
  };
  
  export default StatisticsView;