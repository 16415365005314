import { CapitalizeFirstLowerCase } from "./string-util";
import Moment from 'moment';


/**
 * Donne le jour de la date donnée en fraçais et avec la première lettre en capitale
 * @param myDate Date en entrée
 * @returns Le jour de la semaine
 */
export const GetDayOfWeekFirstLowerCase = (myDate: Date) => {
    return CapitalizeFirstLowerCase(Moment(myDate).locale('fr').format('dddd'));
};

