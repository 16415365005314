import { faker } from '@faker-js/faker';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { AlertModel, INITIAL_ALERT } from './alert-model';
import { AlerteStyle, AlerteTextStyle } from './styles';

const Alert = () => {

    const [alert, setAlert] = useState<AlertModel>(INITIAL_ALERT);

    const [loading, setLoading] = useState(true);

    const alerteData = () => {
        setLoading(true);
        axios.get('http://chevroliere-meteo.hd.free.fr/alert.php')
            .then((res) => {
                setAlert(res.data);
                setLoading(false);
            })
            .catch((e) => { 
                console.log("Alerte component : " + e.message);
                let alert = {
                    level: faker.helpers.arrayElement(['0', '1', '2', '3', '4']),
                    risk: faker.helpers.arrayElement(['Avalanches', 'Crues', 'Pluies inondation', 'Canicule', 'Neige Verglas', 'Grand froid', 'Orages', 'Vent violent'])
                }
                setAlert(alert);
                setLoading(false);
            });
    }
    
    useEffect(() => {
        const timer = setInterval(() => alerteData(), 600000);
        return function cleanup() {
            clearInterval(timer);
        }

    });

    useEffect(() => {
        alerteData();
    }, []);

    return (
        <>
            {loading && <em>Chargement en cours ...</em>}

            {!loading &&
                <>
                    <AlerteStyle alertLevel={alert.level} alertRisk={alert.risk}> 
                        <AlerteTextStyle>Alerte Météo France</AlerteTextStyle>
                        <AlerteTextStyle>{alert.risk}</AlerteTextStyle>
                    </AlerteStyle>
                </>
            }
        </>
    );

};
  
export default Alert;

