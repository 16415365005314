import { GetDayOfWeekFirstLowerCase } from "../../../Utils/date-util";
import { CapitalizeFirstLowerCase } from "../../../Utils/string-util";
import Moment from 'moment';
require('moment/locale/fr.js');

export const INITIAL_FORECAST_DAY_INFO: ForecastDayInfoModel = { link: "", 
                                                                 day: "", 
                                                                 image: "", 
                                                                 imageDesc: "", 
                                                                 tempMin: 0, 
                                                                 tempMax: 0, 
                                                                 wind: 0, 
                                                                 rain: "", 
                                                                 pressure: 0 };

export interface ForecastDayInfoModel {
    link: string;    
    day: string;
    image: string;
    imageDesc: string;
    tempMin: number;
    tempMax: number;
    wind: number;
    rain: string;
    pressure: number;
};

export const INITIAL_FORECAST: ForecastModel = { forecastDayOne: INITIAL_FORECAST_DAY_INFO,
                                                 forecastDayTwo: INITIAL_FORECAST_DAY_INFO,
                                                 forecastDayThree: INITIAL_FORECAST_DAY_INFO,
                                                 forecastDayFour: INITIAL_FORECAST_DAY_INFO,
                                                 forecastDayFive: INITIAL_FORECAST_DAY_INFO};

export interface ForecastModel {
    forecastDayOne: ForecastDayInfoModel,
    forecastDayTwo: ForecastDayInfoModel,
    forecastDayThree: ForecastDayInfoModel,
    forecastDayFour: ForecastDayInfoModel,
    forecastDayFive: ForecastDayInfoModel
};

const GetRain = (rain: number) => {
    if(isNaN(rain)) {
        return "-";
    }
    else {
        return Math.round(rain)+" mm";
    }
}

const CreateForecastDay = (daily: {
    dt: number; 
    weather: any; 
    temp: {
        min: number;
        max: number;
    },
    wind_speed: number,
    rain: number,
    pressure: number
}) => {
    return {
        link: "/forecast/" +  daily.dt,
        day: GetDayOfWeekFirstLowerCase(new Date(daily.dt * 1000)),
        image: "/images/" + daily.weather[0].icon + ".png",
        imageDesc: CapitalizeFirstLowerCase(daily.weather[0].description),
        tempMin: Math.round(daily.temp.min),
        tempMax: Math.round(daily.temp.max),
        wind: Math.round(3.6 * daily.wind_speed),
        rain: GetRain(daily.rain),
        pressure: daily.pressure
    };
};

export function ConvertToForecast(data: any): React.SetStateAction<ForecastModel> {
    let forecast = {
        forecastDayOne: CreateForecastDay(data.daily[0]),
        forecastDayTwo: CreateForecastDay(data.daily[1]),
        forecastDayThree: CreateForecastDay(data.daily[2]),
        forecastDayFour: CreateForecastDay(data.daily[3]),
        forecastDayFive: CreateForecastDay(data.daily[4])
    };

    return forecast;
}

export const INITIAL_FORECAST_DAY_COMPLETE_INFO: ForecastDayCompleteInfoModel = {   sunrise: '',
                                                                                    sunset: '',
                                                                                    moonrise: '',
                                                                                    moonset: '',
                                                                                    moonphase: '',
                                                                                    tempmorn: '',
                                                                                    tempday: '',
                                                                                    tempeve: '',
                                                                                    tempnight: '',
                                                                                    tempmin: '',
                                                                                    tempmax: '',
                                                                                    feelslikemorn: '',
                                                                                    feelslikeday: '',
                                                                                    feelslikeeve: '',
                                                                                    feelslikenight: '',
                                                                                    pressure: '',
                                                                                    humidity: '',
                                                                                    dewpoint: '',
                                                                                    windspeed: '',
                                                                                    windgust: '',
                                                                                    winddeg: '',
                                                                                    pop: '',
                                                                                    rain: '' };

export interface ForecastDayCompleteInfoModel {
    sunrise: string,
    sunset: string,
    moonrise: string,
    moonset: string,
    moonphase: string,
    tempmorn: string,
    tempday: string,
    tempeve: string,
    tempnight: string,
    tempmin: string,
    tempmax: string,
    feelslikemorn: string,
    feelslikeday: string,
    feelslikeeve: string,
    feelslikenight: string,
    pressure: string,
    humidity: string,
    dewpoint: string,
    windspeed: string,
    windgust: string,
    winddeg: string,
    pop: string,
    rain: string
}

export const FormatHeure = (myDate: Date) => { return Moment(myDate).locale('fr').format('HH:mm:SS'); }

export const MoonPhase = (value: number) => {
    if(value === 0 || value === 1) { return "Nouvelle lune"; }
    else if(value > 0 && value < 0.25) { return "Premier croissant de lune"; }
    else if(value === 0.25) { return "Premier quartier de lune"; }
    else if(value > 0.25 && value < 0.5) { return "Lune gibbeuse croissante"; }
    else if(value === 0.5) { return "Pleine lune"; }
    else if(value > 0.5 && value < 0.75) { return "Lune gibbeuse décroissante"; }
    else if(value === 0.75) { return "Dernier quartier de lune"; }
    else if(value > 0.75 && value < 1) { return "Dernier croissant de lune"; }
    else return "";
}

export const ConvertToKmHeure = (value : number) => { return Math.round(3.6 * value); }

export const WindDirection = (value : number) => {
    if(value > 348.75 || value < 11.25) return "Nord";
    else if(value > 11.25 && value <= 33.75) return "Nord Nord Est";
    else if(value > 33.75 && value <= 56.25) return "Nord Est";
    else if(value > 56.25 && value <= 78.75) return "Est Nord Est";
    else if(value > 78.75 && value <= 101.25) return "Est";
    else if(value > 101.25 && value <= 123.75) return "Est Sud Est";
    else if(value > 123.75 && value <= 146.25) return "Sud Est";
    else if(value > 146.25 && value <= 168.75) return "Sud Sud Est";
    else if(value > 168.75 && value <= 191.25) return "Sud";
    else if(value > 191.25 && value <= 213.75) return "Sud Sud Ouest";
    else if(value > 213.75 && value <= 236.25) return "Sud Ouest";
    else if(value > 236.25 && value <= 258.75) return "Ouest Sud Ouest";
    else if(value > 258.75 && value <= 281.25) return "Ouest";
    else if(value > 281.25 && value <= 303.75) return "Ouest Nord Ouest";
    else if(value > 303.75 && value <= 326.25) return "Nord Ouest";
    else if(value > 326.25 && value <= 348.75) return "Nord Nord Ouest";
    else return "";
}

export function ConvertToForecastDayCompleteInfo(dayData: any): React.SetStateAction<ForecastDayCompleteInfoModel> {
    let info = {
        sunrise: FormatHeure(new Date(dayData.sunrise * 1000)),
        sunset: FormatHeure(new Date(dayData.sunset * 1000)),
        moonrise: FormatHeure(new Date(dayData.moonrise * 1000)),
        moonset: FormatHeure(new Date(dayData.moonset * 1000)),
        moonphase: MoonPhase(dayData.moon_phase),
        tempmorn: Math.round(dayData.temp.morn) + " °C",
        tempday: Math.round(dayData.temp.day) + " °C",
        tempeve: Math.round(dayData.temp.eve) + " °C",
        tempnight: Math.round(dayData.temp.night) + " °C",
        tempmin: Math.round(dayData.temp.min) + " °C",
        tempmax: Math.round(dayData.temp.max) + " °C",
        feelslikemorn: Math.round(dayData.feels_like.morn) + " °C",
        feelslikeday: Math.round(dayData.feels_like.day) + " °C",
        feelslikeeve: Math.round(dayData.feels_like.eve) + " °C",
        feelslikenight: Math.round(dayData.feels_like.night) + " °C",
        pressure: dayData.pressure + " hPa",
        humidity: dayData.humidity + " %",
        dewpoint: Math.round(dayData.dew_point) + " °C",
        windspeed: ConvertToKmHeure(dayData.wind_speed) + " Km/h",
        windgust: ConvertToKmHeure(dayData.wind_gust) + " Km/h",
        winddeg: WindDirection(dayData.wind_deg),
        pop: Math.round(100 * dayData.pop) + " %",
        rain: '-'
    };

    if(dayData.rain !== undefined) info.rain = Math.round(dayData.rain) + " mm";

    return info;
}
