import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { DivNowAirQualityStyle, DivNowAirCityStyle, DivNowAqiusStyle, DivNowAirDescriptionStyle } from './styles';
import Loader from '../../../Common/Loader';
import { AirQualityModel, ConvertToAirQuality, INITIAL_AIR_QUALITY } from './air-quality-model';

const AirQuality: React.FC = () => {

    const [airQuality, setAirQuality] = useState<AirQualityModel>(INITIAL_AIR_QUALITY);

    const [loading, setLoading] = useState(true);

    const airQualityData = () => {

        setLoading(true);

        axios.get('https://api.waqi.info/feed/geo:47.080121;-1.580439/?token=431fe9cbd2f6c88528af81c2e477f8184e463d2f')
            .then((res) => {
                setAirQuality(ConvertToAirQuality(res.data.data));
                setLoading(false);
            })
            .catch((e) => {
                console.log("AirQuality component : " + e.message);
                setLoading(false);
            });
    }


    // Rechargement toutes les 10 minutes
    useEffect(() => {
        const timer = setInterval(() => airQualityData(), 600000);

        return function cleanup() {
            clearInterval(timer);
        }

    });

    useEffect(() => {
        airQualityData();
    }, []);

    return (
        <>
            {loading && <Loader />}
            
            {!loading &&
                <>
                    <DivNowAirQualityStyle aqius={airQuality.aqius}>
                        <DivNowAirCityStyle>{airQuality.airCity}</DivNowAirCityStyle>
                        <DivNowAqiusStyle aqius={airQuality.aqius}>{airQuality.aqius}</DivNowAqiusStyle>
                    </DivNowAirQualityStyle>
                    <DivNowAirDescriptionStyle>{airQuality.description}</DivNowAirDescriptionStyle>
                </>
            }
        </>
    );

};
  
export default AirQuality;

