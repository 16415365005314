import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { DivNowCurrentConditionsStyle, ImgStyle } from './styles';
import { CapitalizeFirstLowerCase } from '../../../Utils/string-util';
import Loader from '../../../Common/Loader';

const CurrentConditions: React.FC = () => {

    const [image, setImage] = useState('');
    const [description, setDescription] = useState('');

    const [loading, setLoading] = useState(true);

    const currentConditionsData = () => {

        setLoading(true);

        axios.get('https://api.openweathermap.org/data/2.5/weather?q=La%20Chevroli%C3%A8re&units=metric&lang=fr&appid=70ecc0cb8bcbc3ea284d5162def7f39a')
            .then((res) => {
                setImage("/images/" + res.data.weather[0].icon + ".png");
                setDescription(CapitalizeFirstLowerCase(res.data.weather[0].description));

                setLoading(false);
            })
            .catch((e) => {
                console.log("CurrentConditions component : " + e.message);
                console.log(e.response.data.message);
                setLoading(false);
            });

    }


    // Rechargement toutes les 10 minutes
    useEffect(() => {
        const timer = setInterval(() => currentConditionsData(), 600000);

        return function cleanup() {
            clearInterval(timer);
        }

    });

    useEffect(() => {
        currentConditionsData();
    }, []);

    return (
        <>
            {loading && <Loader />}
            
            {!loading &&
                <>
                    <DivNowCurrentConditionsStyle>
                        <ImgStyle src={image} alt={description} title={description}/>
                    </DivNowCurrentConditionsStyle>
                </>
            }
        </>
    );

};
  
export default CurrentConditions;