import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { GraphicModel } from '../charts-model';

interface Props {
    graphic: GraphicModel;
}

ChartJS.register( CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend );
ChartJS.defaults.color = 'white';
  
const LineChart: React.FC<Props> = ({ graphic }) => {

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top' as const
            },
            title: {
                display: true,
                text: graphic.titre,
                font: { 
                    weight: 'bold', 
                    size: 20 
                }
            }
        },
        tension: 0.3
    };

    if(graphic.chartDatas.length > 1) {
        const data = {
            labels: graphic.labelsX,
            datasets: [
                {
                    label: graphic.chartDatas[0].labelY,
                    data: graphic.chartDatas[0].values,
                    borderColor: '#36A2EB',
                    backgroundColor: '#36A2EB'
                },
                {
                    label: graphic.chartDatas[1].labelY,
                    data: graphic.chartDatas[1].values,
                    borderColor: '#FF6384',
                    backgroundColor: '#FF6384'
                },
                {
                    label: graphic.chartDatas[2].labelY,
                    data: graphic.chartDatas[2].values,
                    borderColor: '#9966FF',
                    backgroundColor: '#9966FF'
                },
            ],
        };

        return (
            <Line options={options} data={data} />
        );  
    }
    else {
        const data = {
            labels: graphic.labelsX,
            datasets: [
                {
                    label: graphic.chartDatas[0].labelY,
                    data: graphic.chartDatas[0].values,
                    borderColor: '#36A2EB',
                    backgroundColor: '#36A2EB'
                }
            ],
        };

        return (
            <Line options={options} data={data} />
        );
    }
    
}

export default LineChart;