import styled from 'styled-components';
import { FaBars } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';

export const PrimaryNavStyle = styled.nav`
    z-index: 14;
    height: 40px;
    display: flex;
    background: #113846;
    justify-content: space-between;
    padding: 0.18rem calc((100vw - 1000px) / 2);
`;

interface MenuLinkProps {
    active: number;
}

export const HamburgerStyle = styled(FaBars)`
    display: none;
    color: #ffffff;
    @media screen and (max-width: 768px) {
        display: block;
        font-size: 1.9rem;
        position: absolute;
        cursor: pointer;
    }
`;

export const MenuHorizontalStyle = styled.div`
    display: flex;
    align-items: center;
    margin-right: -25px;
    @media screen and (max-width: 768px) {
        display: none;
    }
`;

export const MenuLinkStyle = styled(NavLink)<MenuLinkProps>`
    color: #fff;
    display: flex;
    cursor: pointer;
    align-items: center;
    text-decoration: none;
    padding: 0 1.2rem;
    height: 100%;

    ${(props) => (props.active === 1) ? 'border-bottom: solid 2px white;' : 'border:none;'}
`;

export const MenuVerticalStyle = styled.div`
    margin-left: 30px;
`;

export const MenuVerticalLinkStyle = styled(MenuLinkStyle)<MenuLinkProps>`
    background-color: #113846;
    position:relative;
    z-index:1;
`;

