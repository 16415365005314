import React from 'react';
import { DivNowCellStyle, DivNowDayStyle, DivNowPrecipitationsStyle, DivNowValueStyle } from './styles';

interface Props {
    totalPrecipitationsAujourdui: number;
    totalPrecipitationsHier: number;
}

const Precipitations: React.FC<Props> = ({ totalPrecipitationsAujourdui, totalPrecipitationsHier }) => {

    return (
        <>
            <DivNowPrecipitationsStyle>
                <DivNowDayStyle>
                    <DivNowCellStyle>Aujourd'hui</DivNowCellStyle>
                </DivNowDayStyle>
                <DivNowValueStyle>
                    <DivNowCellStyle><span>{totalPrecipitationsAujourdui}</span> mm</DivNowCellStyle>
                </DivNowValueStyle>
                <DivNowDayStyle>
                    <DivNowCellStyle>Hier</DivNowCellStyle>
                </DivNowDayStyle>
                <DivNowValueStyle>
                    <DivNowCellStyle><span>{totalPrecipitationsHier}</span> mm</DivNowCellStyle>
                </DivNowValueStyle>
            </DivNowPrecipitationsStyle>
        </>
    );

};
  
export default Precipitations;