import React, { useEffect, useState } from 'react';
import { ContentStyle } from '../../Common/global-styles';
import axios from 'axios';
import Precipitations from './Precipitations';
import { faker } from '@faker-js/faker';
import Cell from '../../Common/cell';
import { NowModel, INITIAL_NOW } from './now-model';
import AirQuality from './AirQuality';
import CurrentConditions from './CurrentConditions';
import Forecast from './Forecast';
import Pressions from './Pressions';
import Temperature from './Temperature';
import Vent from './Vent';

const NowView: React.FC = () => {

    const [now, setNow] = useState<NowModel>(INITIAL_NOW);
    
    const NowData = () => {
        axios.get('http://chevroliere-meteo.hd.free.fr/now.php')
            .then((res) => {
                setNow(res.data);
            })
            .catch((e) => { 
                console.log("Now component : " + e.message);
                let now = {
                    temperatures: {
                        min:faker.datatype.float({ min: 0, max: 5 }), 
                        max:faker.datatype.float({ min: 10, max: 15 }), 
                        current:faker.datatype.float({ min: 5, max: 10 })
                    },
                    humidites: {
                        min:faker.datatype.float({ min: 30, max: 50 }), 
                        max:faker.datatype.float({ min: 70, max: 90 }), 
                        current:faker.datatype.float({ min: 50, max: 70 })
                    },
                    pressions: {
                        min:faker.datatype.float({ min: 990, max: 1000 }), 
                        max:faker.datatype.float({ min: 1010, max: 1020 }), 
                        current:faker.datatype.float({ min: 1000, max: 1010 })
                    },
                    vitessesVent: {
                        min:faker.datatype.float({ min: 0, max: 5 }), 
                        max:faker.datatype.float({ min: 10, max: 15 }), 
                        current:faker.datatype.float({ min: 5, max: 10 })
                    },
                    directionVentCourant: faker.address.direction(),
                    totalPrecipitationsAujourdui: faker.datatype.float({ min: 5, max: 20 }),
                    totalPrecipitationsHier: faker.datatype.float({ min: 5, max: 20 })
                }
                setNow(now);
            });
    }

    useEffect(() => {
        const timer = setInterval(() => NowData(), 600000);

        return function cleanup() {
            clearInterval(timer);
        }

    });

    useEffect(() => {
        NowData();
    }, []);
        
    return (
        <>
            <ContentStyle>
                <Cell title="Conditions actuelles" content={<CurrentConditions />} />
                <Cell title="Températures (°C) / Humidités (%)" content={<Temperature temperatures={now.temperatures} humidites={now.humidites}/>} />
                <Cell title="Précipitations (mm)" content={<Precipitations totalPrecipitationsAujourdui={now.totalPrecipitationsAujourdui}
                                                                             totalPrecipitationsHier={now.totalPrecipitationsHier}/>} />
                <Cell title="Direction | Vitesse du vent (Km/h)" content={<Vent vitessesVent={now.vitessesVent} directionVentCourant={now.directionVentCourant}/>} />
                <Cell title="Pressions atmostphériques (hPa)" content={<Pressions pressions={now.pressions} />} />
                <Cell title="Qualité de l'air" content={<AirQuality />} />
                <Forecast />
            </ContentStyle>
        </>
    );
  };
  
  export default NowView;