import styled from 'styled-components';
import { createGlobalStyle } from 'styled-components'

export const ContentStyle = styled.div`
    margin: 12px auto!important;
    display: block;
    max-width: 1280px;
`;

export const Div50PercentStyle = styled.div`
    width: 50%;
    float: left;
`;

export const DivCenterStyle = styled.div`
    text-align: center;
`;

export const DivCellStyle = styled.div`
    margin: 5px;
    border: 1px solid #113846;
    float: left;
    width: 400px;
    border-radius: 5px;

    @media screen and (max-width: 1280px) {
        width: 1249px;
    }
    @media screen and (max-width: 1112px) {
        width: 1081px;
    }
    @media screen and (max-width: 1031px) {
        width: 1000px;
    }
    @media screen and (max-width: 1024px) {
        width: 993px;
    }
    @media screen and (max-width: 960px) {
        width: 929px;
    }
    @media screen and (max-width: 950px) {
        width: 919px;
    }
    @media screen and (max-width: 900px) {
        width: 869px;
    }
    @media screen and (max-width: 896px) {
        width: 865px;
    }
    @media screen and (max-width: 846px) {
        width: 815px;
    }
    @media screen and (max-width: 834px) {
        width: 803px;
    }
    @media screen and (max-width: 823px) {
        width: 792px;
    }
    @media screen and (max-width: 812px) {
        width: 781px;
    }
    @media screen and (max-width: 800px) {
        width: 769px;
    }
    @media screen and (max-width: 786px) {
        width: 755px;
    }
    @media screen and (max-width: 768px) {
        width: 737px;
    }
    @media screen and (max-width: 740px) {
        width: 709px;
    }
    @media screen and (max-width: 736px) {
        width: 705px;
    }
    @media screen and (max-width: 732px) {
        width: 701px;
    }
    @media screen and (max-width: 731px) {
        width: 700px;
    }
    @media screen and (max-width: 688px) {
        width: 657px;
    }
    @media screen and (max-width: 667px) {
        width: 636px;
    }
    @media screen and (max-width: 640px) {
        width: 609px;
    }
    @media screen and (max-width: 600px) {
        width: 569px;
    }
    @media screen and (max-width: 568px) {
        width: 537px;
    }
    @media screen and (max-width: 414px) {
        width: 383px;
    }
    @media screen and (max-width: 412px) {
        width: 381px;
    }
    @media screen and (max-width: 393px) {
        width: 362px;
    }
    @media screen and (max-width: 384px) {
        width: 353px;
    }
    @media screen and (max-width: 375px) {
        width: 344px;
    }
    @media screen and (max-width: 360px) {
        width: 329px;
    }
    @media screen and (max-width: 320px) {
        width: 289px;
    }

`;

export const DivCellTitleStyle = styled.div`
    background-color: #113846;
    height: 25px;
    text-align: center;
    vertical-align: middle;
    padding-top: 5px;
`;

export const DivCellContentStyle = styled.div`
    height: 170px;
    display: block;
    background-color: #1D5D75;
`;

export const GlobalStyle = createGlobalStyle`
    body {
        font-family: Verdana, Geneva, Tahoma, sans-serif;
        color: white;
        background-color: #68A0A5;
        margin: 0;
    }

    p {
        margin: 0;
    }
`;

export const Button = styled.button`
  background-color: #1D5D75;
  color: white;
  padding: 5px 15px;
  border-radius: 5px;
  outline: 0;
  text-transform: uppercase;
  margin: 10px 0px;
  cursor: pointer;
  transition: ease background-color 250ms;
  &:hover {
    background-color: #113846;
  }
  &:disabled {
    cursor: default;
    opacity: 0.7;
  }
`;

export const DivDateStyle = styled.div`
    text-align: center;
    color: #113846;
    font-weight: bold;
    font-size: 2em;
`;
