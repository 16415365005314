import styled from "styled-components";


export const TitleStatDivStyle = styled.div`
    padding-top: 7px;
    text-align: center;
    text-decoration: underline;
`;

export const DataStatDivStyle = styled.div`
    padding-top: 10px;
    width: 100%;
`;

export const LabelStatSpanStyle = styled.span`
    width: 50%;
    display: inline-block;
    text-align: right;
`;

export const ValueStatSpanStyle = styled.span`
    width: 45%;
    display: inline-block;
    padding-left: 5px;
    color: #68A0A5;
`;