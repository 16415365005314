import styled from 'styled-components';

export const DivLoaderStyle = styled.div`
    float: left;
    margin-left: 15px;
    margin-top: 10px;
    width: 93%;
    height: 150px;
    text-align: center;
`;

export const ImgStyle = styled.img`
    width: 150px;
    height: 150px;
`;