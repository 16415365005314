import styled from 'styled-components';

export const HeadbandStyle = styled.div`
    height: 80px;
    background: #1D5D75;
`;

export const HeaderDivStyle = styled.div`
    height: 80px;
    background: #1D5D75;
    margin: 0 auto;
    padding: 11px 11px 11px 6px;
    max-width: 1280px;
`;

export const PStyle = styled.p`
    margin-bottom: 5px;
`;

