import styled from 'styled-components';
import { Div50PercentStyle } from '../../global-styles';

interface AlertProps {
    alertLevel: string,
    alertRisk: string
}

export const AlerteStyle = styled(Div50PercentStyle)<AlertProps>`
    float: right;
    height: 55px;
    max-width: 250px;
    text-align: center;
    border-radius: 5px;

    ${(props) => (props.alertRisk === 'RAS' ) ? 'display: none;' : ''};

    background-color: ${(props) =>
        ((props.alertLevel === "0") && '#007100') ||
        ((props.alertLevel === "1") && '#007100') ||
        ((props.alertLevel === "2") && '#FFFF00') ||
        ((props.alertLevel === "3") && '#EF8B00') ||
        ((props.alertLevel === "4") && '#CD0000')
    };

    color: ${(props) => (props.alertLevel === "2" ) ? '#000000' : '#FFFFFF'};
`;

export const AlerteTextStyle = styled.div`
    padding-top: 5px;
`;
