export const INITIAL_KEYNUMBER: KeyNumberModel = { key: '', value: 0 }

export interface KeyNumberModel {
    key: string;
    value: number;
}

export const INITIAL_STATISTIC: StatisticModel = { recordsChaleur: [], recordsFroideur: [], recordsPluie: [], recordsVent: [], recordsHumidite: [],
                                                   recordsSecheresse: []}

export interface StatisticModel {
    recordsChaleur: KeyNumberModel[];
    recordsFroideur: KeyNumberModel[];
    recordsPluie: KeyNumberModel[];
    recordsVent: KeyNumberModel[];
    recordsHumidite: KeyNumberModel[];
    recordsSecheresse: KeyNumberModel[];
}
