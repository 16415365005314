import { faker } from '@faker-js/faker';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import BarChart from '../../Common/Charts/BarChart';
import { CompleteGraphicModel, INITIAL_COMPLETE_GRAPHIC } from '../../Common/Charts/charts-model';
import LineChart from '../../Common/Charts/LineChart';
import { DivChartStyle } from '../../Common/Charts/styles';
import { Button, ContentStyle, DivCenterStyle, DivDateStyle } from '../../Common/global-styles';
import Loader from '../../Common/Loader';
import moment from 'moment';
import { CapitalizeFirstLowerCase } from '../../Utils/string-util';

const TodayView: React.FC = () => {

    const [loading, setLoading] = useState(true);

    const [graphic, setGraphic] = useState<CompleteGraphicModel>(INITIAL_COMPLETE_GRAPHIC);

    const [day, setDay] = useState(new Date());

    const TodayData = (stringDay: string) => {

        setLoading(true);

        axios.get('http://chevroliere-meteo.hd.free.fr/day.php?day='+ stringDay)
            .then((res) => {
                setGraphic(res.data);
                setLoading(false);
            })
            .catch((e) => { 
                console.log("Today component : " + e.message);

                const labelsTemperatures = ['08:00:00', '08:30:00', '09:00:00', '09:30:00', '10:00:00', '10:30:00', '11:00:00'];
                const temperatures = labelsTemperatures.map(() => faker.datatype.number({ min: -5, max: 40 }));
            
                const labelsPrecipitations = ['08:00:00', '08:30:00', '09:00:00', '09:30:00', '10:00:00', '10:30:00', '11:00:00'];
                const precipitations = labelsPrecipitations.map(() => faker.datatype.number({ min: 0, max: 10 }));
            
                const labelsPressions = ['08:00:00', '08:30:00', '09:00:00', '09:30:00', '10:00:00', '10:30:00', '11:00:00'];
                const pressions = labelsPressions.map(() => faker.datatype.number({ min: 990, max: 1030 }));
            
                const labelsVent = ['08:00:00', '08:30:00', '09:00:00', '09:30:00', '10:00:00', '10:30:00', '11:00:00'];
                const vents = labelsVent.map(() => faker.datatype.number({ min: 0, max: 50 }));

                const labelsHumidites = ['08:00:00', '08:30:00', '09:00:00', '09:30:00', '10:00:00', '10:30:00', '11:00:00'];
                const humidites = labelsHumidites.map(() => faker.datatype.number({ min: 0, max: 100 }));

                let graphic = {
                    temperatures: {
                        titre : 'Evolutions des températures', 
                        labelsX : labelsTemperatures, 
                        chartDatas : [{labelY:'Températures du jour', values: temperatures}]
                    },
                    precipitations: {
                        titre : 'Evolutions des précipitations', 
                        labelsX : labelsPrecipitations, 
                        chartDatas : [{labelY:'Précipitations du jour', values: precipitations}]
                    },
                    pressions: {
                        titre : 'Evolutions des pressions atmosphériques', 
                        labelsX : labelsPressions, 
                        chartDatas : [{labelY:'Pressions atmosphériques du jour', values: pressions}]
                    },
                    vents: {
                        titre : 'Evolutions des vitesses du vent', 
                        labelsX : labelsVent, 
                        chartDatas : [{labelY:'Vitesses du vent du jour', values: vents}]
                    },
                    humidites: {
                        titre : "Evolutions de l'humidité", 
                        labelsX : labelsHumidites, 
                        chartDatas : [{labelY:'Humidités du jour', values: humidites}]
                    }
                }
            
                setGraphic(graphic);
                setLoading(false);
            });
    }    

    useEffect(() => {
        TodayData(moment(new Date()).format('YYYYMMDD'));
    }, []);

    function dayBefore(): void {
        const newDate = new Date(day.getTime());
        newDate.setDate(day.getDate() - 1);
        setDay(newDate);
        TodayData(moment(newDate).format('YYYYMMDD'));
    }

    function dayAfter(): void {
        const newDate = new Date(day.getTime());
        newDate.setDate(day.getDate() + 1);
        setDay(newDate);
        TodayData(moment(newDate).format('YYYYMMDD'));
    }

    return (
        <>
            {loading && <Loader />}

            {!loading &&
            <>
                <ContentStyle>
                    <DivDateStyle>{CapitalizeFirstLowerCase(moment(day).format('dddd DD MMMM YYYY'))}</DivDateStyle>
                    <DivCenterStyle>
                        <Button onClick={dayBefore}>Jour précédent</Button>&nbsp;
                        <Button onClick={dayAfter}>Jour suivant</Button>
                    </DivCenterStyle>
                    <DivChartStyle>
                        <LineChart graphic={graphic.temperatures} />
                    </DivChartStyle>
                    <DivChartStyle>
                        <BarChart graphic={graphic.precipitations} />
                    </DivChartStyle>
                    <DivChartStyle>
                        <LineChart graphic={graphic.pressions} />
                    </DivChartStyle>
                    <DivChartStyle>
                        <LineChart graphic={graphic.vents}/>
                    </DivChartStyle>
                    <DivChartStyle>
                        <LineChart graphic={graphic.humidites} />
                    </DivChartStyle>
                </ContentStyle>
            </>
            }
        </>
    );
  };
  
  export default TodayView;