import React from 'react';
import { DivCellContentStyle, DivCellStyle, DivCellTitleStyle } from './global-styles';

interface Props {
    title: string,
    content: React.ReactNode
}

const Cell: React.FC<Props> = ({title, content}) => {

    return (
        <>
        <DivCellStyle>
            <DivCellTitleStyle>{title}</DivCellTitleStyle>
            <DivCellContentStyle>{content}</DivCellContentStyle>
        </DivCellStyle>
        </>
    );
  };
  
  export default Cell;