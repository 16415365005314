export const INITIAL_CHART_DATA: ChartDataModel = { values: [], labelY: ''}

export interface ChartDataModel {
    values: number[];
    labelY: string;
};

export const INITIAL_GRAPHIC: GraphicModel = { titre: '', labelsX: [], chartDatas: []}

export interface GraphicModel {
    titre: string;
    labelsX: string[];
    chartDatas: ChartDataModel[];
}

export const INITIAL_COMPLETE_GRAPHIC: CompleteGraphicModel = { temperatures: INITIAL_GRAPHIC,
                                                                precipitations: INITIAL_GRAPHIC,
                                                                pressions: INITIAL_GRAPHIC,
                                                                vents: INITIAL_GRAPHIC,
                                                                humidites: INITIAL_GRAPHIC}

export interface CompleteGraphicModel {
    temperatures: GraphicModel,
    precipitations: GraphicModel,
    pressions: GraphicModel,
    vents: GraphicModel,
    humidites: GraphicModel
}