import React from 'react';
import { MinMaxCurrentModel } from '../now-model';
import { DivNowTemperatureStyle, DivNowMinMaxStyle, DivNowCellStyle, DivNowCurrentStyle } from './styles';

interface Props {
    temperatures: MinMaxCurrentModel;
    humidites: MinMaxCurrentModel;
}

const Temperature: React.FC<Props> = ({temperatures, humidites}) => {

    return (
        <>
            <DivNowTemperatureStyle>
                <DivNowMinMaxStyle>
                    <DivNowCellStyle><span>{temperatures.min}</span> °C | <span>{temperatures.max}</span> °C</DivNowCellStyle>
                </DivNowMinMaxStyle>
                <DivNowCurrentStyle>
                    <DivNowCellStyle><span>{temperatures.current}</span> °C</DivNowCellStyle>
                </DivNowCurrentStyle>
                <DivNowMinMaxStyle>
                    <DivNowCellStyle><span>{humidites.min}</span> % | <span>{humidites.max}</span> %</DivNowCellStyle>
                </DivNowMinMaxStyle>
                <DivNowCurrentStyle>
                    <DivNowCellStyle><span>{humidites.current}</span> %</DivNowCellStyle>
                </DivNowCurrentStyle>
            </DivNowTemperatureStyle>
        </>
    );

};
  
export default Temperature;