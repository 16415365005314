import { faker } from '@faker-js/faker';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import BarChart from '../../Common/Charts/BarChart';
import { CompleteGraphicModel, INITIAL_COMPLETE_GRAPHIC } from '../../Common/Charts/charts-model';
import LineChart from '../../Common/Charts/LineChart';
import { DivChartStyle } from '../../Common/Charts/styles';
import { Button, ContentStyle, DivCenterStyle, DivDateStyle } from '../../Common/global-styles';
import Loader from '../../Common/Loader';
import moment from 'moment';

const YearView: React.FC = () => {

    const [loading, setLoading] = useState(true);

    const [graphic, setGraphic] = useState<CompleteGraphicModel>(INITIAL_COMPLETE_GRAPHIC);

    const [lastDay, setLastDay] = useState(new Date());

    const YearData = (stringDay: string) => {

        setLoading(true);

        console.log(stringDay);

        axios.get('http://chevroliere-meteo.hd.free.fr/year.php?day=' + stringDay)
            .then((res) => {
                setGraphic(res.data);
                setLoading(false);
            })
            .catch((e) => { 
                console.log("Year component : " + e.message);

                const labelsTemperatures = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet'];
                const temperaturesMax = labelsTemperatures.map(() => faker.datatype.number({ min: 25, max: 40 }));
                const temperaturesMin = labelsTemperatures.map(() => faker.datatype.number({ min: -5, max: 10 }));
                const temperaturesMoy = labelsTemperatures.map(() => faker.datatype.number({ min: 10, max: 25 }));
            
                const labelsPrecipitations = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet'];
                const precipitations = labelsPrecipitations.map(() => faker.datatype.number({ min: 0, max: 10 }));
            
                const labelsPressions = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet'];
                const pressionsMax = labelsPressions.map(() => faker.datatype.number({ min: 1015, max: 1030 }));
                const pressionsMin = labelsPressions.map(() => faker.datatype.number({ min: 990, max: 1000 }));
                const pressionsMoy = labelsPressions.map(() => faker.datatype.number({ min: 1000, max: 1015 }));
            
                const labelsVent = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet'];
                const ventsMax = labelsVent.map(() => faker.datatype.number({ min: 30, max: 50 }));
                const ventsMin = labelsVent.map(() => faker.datatype.number({ min: 0, max: 15 }));
                const ventsMoy = labelsVent.map(() => faker.datatype.number({ min: 15, max: 30 }));
            
                const labelsHumidites = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet'];
                const humiditesMax = labelsHumidites.map(() => faker.datatype.number({ min: 70, max: 100 }));
                const humiditesMin = labelsHumidites.map(() => faker.datatype.number({ min: 0, max: 30 }));
                const humiditesMoy = labelsHumidites.map(() => faker.datatype.number({ min: 31, max: 69 }));

                let graphic = {
                    temperatures: {
                        titre : 'Evolutions des températures', 
                        labelsX : labelsTemperatures, 
                        chartDatas : [{labelY:'Moyenne des températures', values: temperaturesMoy}, 
                                      {labelY:'Températures maximales', values: temperaturesMax}, 
                                      {labelY:'Températures minimales', values: temperaturesMin}]
                    },
                    precipitations: {
                        titre : 'Evolutions des précipitations', 
                        labelsX : labelsPrecipitations, 
                        chartDatas : [{labelY:"Précipitations de l'année", values: precipitations}]
                    },
                    pressions: {
                        titre : 'Evolutions des pressions atmosphériques', 
                        labelsX : labelsPressions, 
                        chartDatas : [{labelY:'Moyenne des pressions atmosphériques', values: pressionsMoy}, 
                                      {labelY:'Pressions atmosphériques maximales', values: pressionsMax}, 
                                      {labelY:'Pressions atmosphériques minimales', values: pressionsMin}]
                    },
                    vents: {
                        titre : 'Evolutions des vitesses du vent', 
                        labelsX : labelsVent, 
                        chartDatas : [{labelY:'Moyenne des vitesses du vent', values: ventsMoy}, 
                                      {labelY:'Vitesses du vent maximales', values: ventsMax}, 
                                      {labelY:'Vitesses du vent minimales', values: ventsMin}]
                    },
                    humidites: {
                        titre : 'Evolutions des humidités', 
                        labelsX : labelsHumidites, 
                        chartDatas : [{labelY:'Moyenne des humidités', values: humiditesMoy}, 
                                      {labelY:'Humidités maximales', values: humiditesMax}, 
                                      {labelY:'Humidités minimales', values: humiditesMin}]
                    }
                }
            
                setGraphic(graphic);
                setLoading(false);
            });
    }    

    useEffect(() => {
        YearData(moment(new Date()).format('YYYYMMDD'));
    }, []);

    function yearBefore(): void {
        const newDate = new Date(lastDay.getTime());
        newDate.setMonth(lastDay.getMonth() - 12);
        setLastDay(newDate);
        YearData(moment(newDate).format('YYYYMMDD'));
    }

    function yearAfter(): void {
        const newDate = new Date(lastDay.getTime());
        newDate.setMonth(lastDay.getMonth() + 12);
        setLastDay(newDate);
        YearData(moment(newDate).format('YYYYMMDD'));
    }

    return (
        <>
            {loading && <Loader />}

            {!loading &&
            <>
                <ContentStyle>
                    <DivDateStyle>De {moment(lastDay).subtract(11, "month").format('MMMM YYYY')} à {moment(lastDay).format('MMMM YYYY')}</DivDateStyle>
                    <DivCenterStyle>
                        <Button onClick={yearBefore}>Année précédente</Button>&nbsp;
                        <Button onClick={yearAfter}>Année suivante</Button>
                    </DivCenterStyle>
                    <DivChartStyle>
                        <LineChart graphic={graphic.temperatures} />
                    </DivChartStyle>
                    <DivChartStyle>
                        <BarChart graphic={graphic.precipitations} />
                    </DivChartStyle>
                    <DivChartStyle>
                        <LineChart graphic={graphic.pressions} />
                    </DivChartStyle>
                    <DivChartStyle>
                        <LineChart graphic={graphic.vents} />
                    </DivChartStyle>
                    <DivChartStyle>
                        <LineChart graphic={graphic.humidites} />
                    </DivChartStyle>
                </ContentStyle>
            </>
        }
        </>
    );
  };
  
  export default YearView;