import React, { useState } from 'react';
import { useMatch } from 'react-router-dom';
import { PrimaryNavStyle, HamburgerStyle, MenuLinkStyle, MenuHorizontalStyle, MenuVerticalStyle, MenuVerticalLinkStyle } from './styles';

const NavBar = () => {

    const matchNow = useMatch('/');
    const matchToday = useMatch('/today');
    const matchWeek = useMatch('/week');
    const matchMonth = useMatch('/month');
    const matchYear = useMatch('/year');
    const matchStatistiques = useMatch('/statistics');

    const [isOpen, setIsOpen] = useState(false);

    const toggleDropDown = () => {
        setIsOpen(isOpen => !isOpen);
    }

    return (
        <>
            <PrimaryNavStyle>
                <HamburgerStyle onClick={toggleDropDown} />
                {isOpen &&
                    <MenuVerticalStyle>
                        <MenuVerticalLinkStyle onClick={() => toggleDropDown()} to='/' active={(matchNow ? 1 : 0)}>Maintenant</MenuVerticalLinkStyle>
                        <MenuVerticalLinkStyle onClick={() => toggleDropDown()} to='/today' active={(matchToday ? 1 : 0)}>Aujourd'hui</MenuVerticalLinkStyle>
                        <MenuVerticalLinkStyle onClick={() => toggleDropDown()} to='/week' active={(matchWeek ? 1 : 0)}>7 jours</MenuVerticalLinkStyle>
                        <MenuVerticalLinkStyle onClick={() => toggleDropDown()} to='/month' active={(matchMonth ? 1 : 0)}>Mensuel</MenuVerticalLinkStyle>
                        <MenuVerticalLinkStyle onClick={() => toggleDropDown()} to='/year' active={(matchYear ? 1 : 0)}>Annuel</MenuVerticalLinkStyle>
                        <MenuVerticalLinkStyle onClick={() => toggleDropDown()} to='/statistics' active={(matchStatistiques ? 1 : 0)}>Statistiques</MenuVerticalLinkStyle>
                    </MenuVerticalStyle>
                }
                <MenuHorizontalStyle>
                    <MenuLinkStyle to='/' active={(matchNow ? 1 : 0)}>Maintenant</MenuLinkStyle>
                    <MenuLinkStyle to='/today' active={(matchToday ? 1 : 0)}>Aujourd'hui</MenuLinkStyle>
                    <MenuLinkStyle to='/week' active={(matchWeek ? 1 : 0)}>7 jours</MenuLinkStyle>
                    <MenuLinkStyle to='/month' active={(matchMonth ? 1 : 0)}>Mensuel</MenuLinkStyle>
                    <MenuLinkStyle to='/year' active={(matchYear ? 1 : 0)}>Annuel</MenuLinkStyle>
                    <MenuLinkStyle to='/statistics' active={(matchStatistiques ? 1 : 0)}>Statistiques</MenuLinkStyle>
                </MenuHorizontalStyle>
            </PrimaryNavStyle>
        </>
    );
};
  
export default NavBar;

