import styled from 'styled-components';

export const DivNowVentStyle = styled.div`
    padding-top: 10px;
    height: 150px;
`;

export const DivNowMinMaxStyle = styled.div`
    color: white;
    font-size: 1em;
`;

export const DivNowCurrentStyle = styled.div`
    color: white;
    font-size: 2em;
`;

export const DivNowCellStyle = styled.div`
    padding-top: 5px;
    text-align: center;
`;