import { Link } from "react-router-dom";
import styled from "styled-components";
import { DivCenterStyle } from "../../../Common/global-styles";

export const DivForecastStyle = styled.div`
    margin: 5px;
    border: 1px solid #113846;
    float: left;
    width: 1225px;
    border-radius: 5px;

    @media screen and (max-width: 1280px) {
        width: 1249px;
    }
    @media screen and (max-width: 1112px) {
        width: 1081px;
    }
    @media screen and (max-width: 1031px) {
        width: 1000px;
    }
    @media screen and (max-width: 1024px) {
        width: 993px;
    }
    @media screen and (max-width: 960px) {
        width: 929px;
    }
    @media screen and (max-width: 950px) {
        width: 919px;
    }
    @media screen and (max-width: 900px) {
        width: 869px;
    }
    @media screen and (max-width: 896px) {
        width: 865px;
    }
    @media screen and (max-width: 846px) {
        width: 815px;
    }
    @media screen and (max-width: 834px) {
        width: 803px;
    }
    @media screen and (max-width: 823px) {
        width: 792px;
    }
    @media screen and (max-width: 812px) {
        width: 781px;
    }
    @media screen and (max-width: 800px) {
        width: 769px;
    }
    @media screen and (max-width: 786px) {
        width: 755px;
    }
    @media screen and (max-width: 768px) {
        width: 737px;
    }
    @media screen and (max-width: 740px) {
        width: 709px;
    }
    @media screen and (max-width: 736px) {
        width: 705px;
    }
    @media screen and (max-width: 732px) {
        width: 701px;
    }
    @media screen and (max-width: 731px) {
        width: 700px;
    }
    @media screen and (max-width: 688px) {
        width: 657px;
    }
    @media screen and (max-width: 667px) {
        width: 636px;
    }
    @media screen and (max-width: 640px) {
        width: 609px;
    }
    @media screen and (max-width: 600px) {
        width: 569px;
    }
    @media screen and (max-width: 568px) {
        width: 537px;
    }
    @media screen and (max-width: 414px) {
        width: 383px;
    }
    @media screen and (max-width: 393px) {
        width: 362px;
    }
    @media screen and (max-width: 384px) {
        width: 353px;
    }
    @media screen and (max-width: 375px) {
        width: 344px;
    }
    @media screen and (max-width: 360px) {
        width: 329px;
    }
    @media screen and (max-width: 320px) {
        width: 289px;
    }
`;

export const DivForecastTitleStyle = styled.div`
    background-color: #113846;
    height: 25px;
    text-align: center;
    vertical-align: middle;
    padding-top: 5px;
`;

export const DivForecastContentStyle = styled.div`
    display: block;
    background-color: #1D5D75;
`;

export const DivForecastDayStyle = styled.div`
    margin: 5px;
    border: 1px solid #113846;
    float: left;
    width: 233px;
    border-radius: 5px;

    @media screen and (max-width: 1280px) {
        width: 237px;
    }
    @media screen and (max-width: 1112px) {
        width: 203px;
    }
    @media screen and (max-width: 1031px) {
        width: 188px;
    }
    @media screen and (max-width: 1024px) {
        width: 186px;
    }
    @media screen and (max-width: 960px) {
        width: 173px;
    }
    @media screen and (max-width: 950px) {
        width: 171px;
    }
    @media screen and (max-width: 900px) {
        width: 161px;
    }
    @media screen and (max-width: 896px) {
        width: 161px;
    }
    @media screen and (max-width: 846px) {
        width: 802px;
    }
    @media screen and (max-width: 834px) {
        width: 790px;
    }
    @media screen and (max-width: 823px) {
        width: 779px;
    }
    @media screen and (max-width: 812px) {
        width: 768px;
    }
    @media screen and (max-width: 800px) {
        width: 756px;
    }
    @media screen and (max-width: 786px) {
        width: 742px;
    }
    @media screen and (max-width: 768px) {
        width: 724px;
    }
    @media screen and (max-width: 740px) {
        width: 696px;
    }
    @media screen and (max-width: 736px) {
        width: 692px;
    }
    @media screen and (max-width: 732px) {
        width: 688px;
    }
    @media screen and (max-width: 731px) {
        width: 687px;
    }
    @media screen and (max-width: 688px) {
        width: 644px;
    }
    @media screen and (max-width: 667px) {
        width: 623px;
    }
    @media screen and (max-width: 640px) {
        width: 596px;
    }
    @media screen and (max-width: 600px) {
        width: 556px;
    }
    @media screen and (max-width: 568px) {
        width: 524px;
    }
    @media screen and (max-width: 414px) {
        width: 370px;
    }
    @media screen and (max-width: 412px) {
        width: 370px;
    }
    @media screen and (max-width: 411px) {
        width: 369px;
    }
    @media screen and (max-width: 393px) {
        width: 349px;
    }
    @media screen and (max-width: 384px) {
        width: 340px;
    }
    @media screen and (max-width: 375px) {
        width: 331px;
    }
    @media screen and (max-width: 360px) {
        width: 316px;
    }
    @media screen and (max-width: 320px) {
        width: 276px;
    }

`;

export const DivForecastDayTitleStyle = styled.div`
    background-color: #1D5D75;
    height: 25px;
    vertical-align: middle;
    padding-top: 5px;
    text-align: center;
    font-size: 1.1em;
`;

export const DivForecastDayContentStyle = styled.div`
    display: block;
    background-color: #1D5D75;
`;

export const LinkForecastDayStyle = styled(Link)`
    color: white;
`;

export const ImgStyle = styled.img`
    width: 150px;
    height: 150px;
`;

export const DivForecastDayInfoStyle = styled(DivCenterStyle)`
    font-size: 0.9em;
    padding-bottom: 5px;
`;

export const ForecastTableStyle = styled.table`
    font-size: 1em;
    border-collapse: collapse;
    border-spacing: 0;
    margin: 12px auto!important;
    max-width: 1280px;
    background-color: #1D5D75;
    border-radius: 5px;

    @media screen and (max-width: 846px) {
        font-size: 0.96em;
        width: 819px;
    }
    @media screen and (max-width: 823px) {
        font-size: 0.94em;
        width: 796px;
    }
    @media screen and (max-width: 812px) {
        font-size: 0.92em;
        width: 785px;
    }
    @media screen and (max-width: 786px) {
        font-size: 0.89em;
        width: 759px;
    }
    @media screen and (max-width: 740px) {
        font-size: 0.84em;
        width: 713px;
    }
    @media screen and (max-width: 736px) {
        font-size: 0.83em;
        width: 709px;
    }
    @media screen and (max-width: 688px) {
        font-size: 0.79em;
        width: 675px;
    }
    @media screen and (max-width: 667px) {
        font-size: 0.75em;
        width: 640px;
    }
    @media screen and (max-width: 640px) {
        font-size: 0.72em;
        width: 613px;
    }
    @media screen and (max-width: 600px) {
        font-size: 0.66em;
        width: 573px;
    }
    @media screen and (max-width: 568px) {
        font-size: 0.63em;
        width: 539px;
    }
    @media screen and (max-width: 414px) {
        font-size: 0.46em;
        width: 400px;
    }
    @media screen and (max-width: 393px) {
        font-size: 0.44em;
        width: 380px;
    }
    @media screen and (max-width: 384px) {
        font-size: 0.41em;
        width: 360px;
    }
    @media screen and (max-width: 375px) {
        font-size: 0.4em;
        width: 350px;
    }
    @media screen and (max-width: 360px) {
        font-size: 0.37em;
        width: 335px;
    }

`;

export const TDStyle = styled.td`
    text-align: right;
    height: 30px;
`;

export const TDValueStyle = styled(TDStyle)`
    color: #68A0A5;
    text-align: left;
`;

export const TDValue3Style = styled(TDValueStyle)`
    colspan: 3;
`;

export const TD6Style = styled(TDStyle)`
    colspan: 6;
`;

