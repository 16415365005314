import { DivLoaderStyle, ImgStyle } from "./styles";

const Loader: React.FC = () => {
    return (
        <DivLoaderStyle>
            <ImgStyle src="/images/loading.gif" alt='Chargement en cours ...' title='Chargement en cours ...' />
        </DivLoaderStyle>
    );
};
  
export default Loader;