import React from 'react';
import { DivCenterStyle } from '../../../Common/global-styles';
import { ForecastDayInfoModel } from './forecast-model';
import { LinkForecastDayStyle, DivForecastDayStyle, DivForecastDayTitleStyle, DivForecastDayContentStyle, ImgStyle, DivForecastDayInfoStyle } from './styles';

interface Props {
    forecastDayInfo: ForecastDayInfoModel;
}

const ForecastDay: React.FC<Props> = ({forecastDayInfo}) => {

    return (
        <>
            <LinkForecastDayStyle to={forecastDayInfo.link}>
                <DivForecastDayStyle>
                    <DivForecastDayTitleStyle>{forecastDayInfo.day}</DivForecastDayTitleStyle>
                    <DivForecastDayContentStyle>
                        <DivCenterStyle><ImgStyle src={forecastDayInfo.image} title={forecastDayInfo.imageDesc} alt={forecastDayInfo.imageDesc} /></DivCenterStyle>
                        <DivForecastDayInfoStyle>Temp. : {forecastDayInfo.tempMin} / {forecastDayInfo.tempMax} %C</DivForecastDayInfoStyle>
                        <DivForecastDayInfoStyle>Vent : {forecastDayInfo.wind} Km/h</DivForecastDayInfoStyle>
                        <DivForecastDayInfoStyle>Précipitations : {forecastDayInfo.rain}</DivForecastDayInfoStyle>
                        <DivForecastDayInfoStyle>Pressions : {forecastDayInfo.pressure} hPa</DivForecastDayInfoStyle>
                    </DivForecastDayContentStyle>
                </DivForecastDayStyle>
            </LinkForecastDayStyle>
        </>
    );
};

export default ForecastDay;