import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { GlobalStyle } from './Common/global-styles';
import Header from './Common/Header';
import NavBar from './Common/NavBar';
import ForecastDayCompleteInfo from './Views/Now/Forecast/forecastDayCompleteInfo';
import NowView from './Views/Now';
import TodayView from './Views/Today';
import WeekView from './Views/Week';
import MonthView from './Views/Month';
import YearView from './Views/Year';
import StatisticsView from './Views/Statistics';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <>
    <GlobalStyle />
    <BrowserRouter>
      <Header/>
      <NavBar />
      <Routes>
        <Route path="/" element={<NowView />} />
        <Route path="/today" element={<TodayView />} />
        <Route path="/week" element={<WeekView />} />
        <Route path="/month" element={<MonthView />} />
        <Route path="/year" element={<YearView />} />
        <Route path="/statistics" element={<StatisticsView />} />
        <Route path="/forecast/:dt" element={<ForecastDayCompleteInfo />} />
      </Routes>
    </BrowserRouter>
  </>
);

/*
  <React.StrictMode>    
  </React.StrictMode>

*/