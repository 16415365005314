import styled from 'styled-components';

export const DivNowPressionMaxStyle = styled.div`
    padding-left: 5px;
    padding-top: 5px;
    height: 20px;
    font-size: 1em;
`;

export const SpanNowPressionMaxStyle = styled.span`

`;

export const DivNowPressionCurrentStyle = styled.div`
    text-align: center;
    font-size: 2.5em;
    height: 115px;
    line-height: 120px;
`;

export const DivNowPressionMinStyle = styled.div`
    padding-right: 5px;
    text-align: right;
    height: 20px;
    font-size: 1em;
`;

export const SpanNowPressionMinStyle = styled.span`

`;
