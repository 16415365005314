import React from 'react';
import { MinMaxCurrentModel } from '../now-model';
import { DivNowPressionCurrentStyle, DivNowPressionMaxStyle, DivNowPressionMinStyle, SpanNowPressionMaxStyle, SpanNowPressionMinStyle } from './styles';

interface Props {
    pressions: MinMaxCurrentModel;
}

const Pressions: React.FC<Props> = ({pressions}) => {

    return (
        <>
            <DivNowPressionMaxStyle>Max <SpanNowPressionMaxStyle>{pressions.max} hPa</SpanNowPressionMaxStyle></DivNowPressionMaxStyle>
            <DivNowPressionCurrentStyle><span>{pressions.current}</span> hPa</DivNowPressionCurrentStyle>
            <DivNowPressionMinStyle>Min <SpanNowPressionMinStyle>{pressions.min} hPa</SpanNowPressionMinStyle></DivNowPressionMinStyle>
        </>
    );

};
  
export default Pressions;