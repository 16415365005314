
/**
 * Met en capital la première lettre d'une chaine de caractères
 * @param str La chaine de caractères en entrée
 * @returns La chaine avec la première lettre en capitale
 */
export const CapitalizeFirstLowerCase = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

