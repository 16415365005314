import styled from 'styled-components';

interface AqiusProps {
    aqius: number;
}

export const DivNowAirQualityStyle = styled.div<AqiusProps>`
    float: left;
    margin-left: 15px;
    margin-top: 10px;
    width: 35%;
    height: 150px;
    background-color: ${(props) =>
        ((props.aqius <= 50) && '#00E400') ||
        ((props.aqius > 50 && props.aqius <= 100) && 'yellow') ||
        ((props.aqius > 100 && props.aqius <= 150) && '#FF7E00') ||
        ((props.aqius > 150 && props.aqius <= 200) && 'red') ||
        ((props.aqius > 200 && props.aqius <= 300) && '#8F3F97') ||
        ((props.aqius > 200) && '#7E0023')
    }
`;

export const DivNowAirCityStyle = styled.div`
    background-color: #113846;
    height: 25px;
    font-size: 0.9em;
    text-align: center;
    padding-top: 5px;
`;

export const DivNowAqiusStyle = styled.div<AqiusProps>`
    height: 120px;
    line-height: 120px;
    text-align: center;
    font-size: 5em;
    color: ${(props) =>
        ((props.aqius <= 50) && 'black') ||
        ((props.aqius > 50 && props.aqius <= 100) && 'black') ||
        ((props.aqius > 100 && props.aqius <= 150) && 'white') ||
        ((props.aqius > 150 && props.aqius <= 200) && 'white') ||
        ((props.aqius > 200 && props.aqius <= 300) && 'white') ||
        ((props.aqius > 200) && 'white')
    }
`;

export const DivNowAirDescriptionStyle = styled.div`
    padding: 2px;
    font-size: 0.8em;
    float: left;
    margin-left: 20px;
    margin-top: 10px;
    width: 50%;
    height: 150px;
`;
