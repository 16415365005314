import React from 'react';
import { MinMaxCurrentModel } from '../now-model';
import { DivNowCellStyle, DivNowCurrentStyle, DivNowMinMaxStyle, DivNowVentStyle } from './styles';

interface Props {
    vitessesVent: MinMaxCurrentModel;
    directionVentCourant: string;
}

const Vent: React.FC<Props> = ({vitessesVent, directionVentCourant}) => {

    return (
        <>
            <DivNowVentStyle>
                <DivNowMinMaxStyle>
                    <DivNowCellStyle><span>{vitessesVent.min}</span> km/h | <span>{vitessesVent.max}</span> km/h</DivNowCellStyle>
                </DivNowMinMaxStyle>
                <DivNowCurrentStyle>
                    <DivNowCellStyle><span>{vitessesVent.current}</span> km/h</DivNowCellStyle>
                </DivNowCurrentStyle>
                <DivNowMinMaxStyle>
                    <DivNowCellStyle>&nbsp;</DivNowCellStyle>
                </DivNowMinMaxStyle>
                <DivNowCurrentStyle>
                    <DivNowCellStyle><span>{directionVentCourant}</span></DivNowCellStyle>
                </DivNowCurrentStyle>
            </DivNowVentStyle>
        </>
    );

};
  
export default Vent;