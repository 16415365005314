export const INITIAL_AIR_QUALITY: AirQualityModel = { aqius: 0, 
                                                      airCity: '',
                                                      description: ''};

export interface AirQualityModel {
    aqius: number;
    airCity: string;
    description: string;
};

export function ConvertToAirQuality(data: any): React.SetStateAction<AirQualityModel> {
    let airQuality = {
        aqius: data.aqi,
        airCity: data.city.name.split(',')[1],
        description: ''
    }

    if(data.aqi <= 50) {
        airQuality.description = 'La qualité de l\'air est satisfaisante et la pollution atmosphérique ne présente que peu ou pas de risques.';
    }
    else if((data.aqi > 50) && (data.aqi <= 100)) {
        airQuality.description = 'La qualité de l\'air est acceptable. Cependant, il peut y avoir un risque pour certaines personnes, en particulier celles qui sont habituellement sensibles à la pollution atmosphérique.';
    }
    else if((data.aqi > 100) && (data.aqi <= 150)) {
        airQuality.description = 'Les membres de groupes sensibles peuvent ressentir des effets sur leur santé. Le grand public est moins susceptible d\'être affecté.';
    }
    else if((data.aqi > 150) && (data.aqi <= 200)) {
        airQuality.description = 'Certains membres du grand public peuvent ressentir des effets sur leur santé. Les membres de groupes sensibles peuvent ressentir des effets plus graves sur leur santé.';
    }
    else if((data.aqi > 200) && (data.aqi <= 300)) {
        airQuality.description = 'Alerte santé: Le risque d\'effets sur la santé est accru pour tout le monde.';
    }
    else if(data.aqi > 300) {
        airQuality.description = 'Avertissement sanitaire des conditions d\'urgence: tout le monde est plus susceptible d\'être affecté.';
    }

    return airQuality;
}


