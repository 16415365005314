import React, { useEffect, useState } from 'react';
import { HeadbandStyle, HeaderDivStyle, PStyle } from './styles';
import { Div50PercentStyle } from '../global-styles';
import Alert from './Alert';
import { CapitalizeFirstLowerCase } from '../../Utils/string-util';
import Moment from 'moment';
require('moment/locale/fr.js');

const Header = () => {

    const [date, setDate] = useState(new Date());

    const formatDate = CapitalizeFirstLowerCase(Moment(date).locale('fr').format('dddd DD MMMM YYYY'));

    useEffect(() => {
        const timer = setInterval(() => setDate(new Date()), 1000);

        return function cleanup() {
            clearInterval(timer);
        }

    });

    return (
    <>
        <HeadbandStyle>
            <HeaderDivStyle>
                <Div50PercentStyle>
                    <PStyle>{formatDate}</PStyle>
                    <PStyle>{date.toLocaleTimeString()}</PStyle>
                </Div50PercentStyle>
                <Alert />
            </HeaderDivStyle>
        </HeadbandStyle>
    </>
    );

};
  
export default Header;
